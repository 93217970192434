import React, { useCallback, useState, useEffect, useMemo } from 'react'
import isHotkey from 'is-hotkey'
//import { Editable, withReact, ReactEditor, useSlate, Slate, useSelected, useFocused } from 'slate-react'
import { Editable, withReact, useSlate, Slate, useSelected, useFocused } from 'slate-react'
import {
  Editor,
  Transforms,
  createEditor,
  Element as SlateElement,
} from 'slate'
import { withHistory } from 'slate-history'
import imageExtensions from './ImageExtensions'
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
//import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import { useDropzone } from 'react-dropzone';
import { Typography, Box, Paper, Stack } from '@mui/material';
import { UploadReportFile } from '../../api';

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
}
const LIST_TYPES = ['numbered-list', 'bulleted-list']
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']

const isUrl = url => /^[^\s.]+\.\S{2,}$/.test(url)

const RichText = (props) => {
  const renderElement = useCallback(props => <Element {...props} />, [])
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])
  const editor = useMemo(() => withImages(withHistory(withReact(createEditor()))), [])
  const [uploading, setUploading] = useState(false)

  const onDrop = useCallback(async acceptedFiles => {
    setUploading(true)
    await UploadReportFile(acceptedFiles, (file, result) => {
      const text = { text: '' }
      const image = { type: 'image', url: result, children: [text]}
      Transforms.insertNodes(editor, image)
    }).catch(e=>e)
    setUploading(false)
  }, [editor])

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, accept: {'image/*': ['.jpeg', '.jpg', '.gif', '.png']}})

  const rootProps = {...getRootProps({onClick: e => imageInsertClick(e, editor)})}
  delete rootProps.tabIndex
  const topRootProps = {...rootProps}
  delete topRootProps.onClick
  delete topRootProps.onKeyDown

  // Move cursor to the end for tabbing
  useEffect(() => Transforms.select(editor, Editor.end(editor, [])), [editor])

  return (
    <Box sx={{position:'relative'}} {...topRootProps}>
      <input {...getInputProps({})} />
      <Slate editor={editor} {...props}>
        <Toolbar>
          <MarkButton title="Bold" format="bold"><FormatBoldIcon /></MarkButton>
          <MarkButton title="Italic" format="italic"><FormatItalicIcon /></MarkButton>
          <MarkButton title="Underline" format="underline"><FormatUnderlinedIcon /></MarkButton>
          <BlockButton title="Heading 1" format="heading-one"><LooksOneIcon /></BlockButton>
          <BlockButton title="Heading 2" format="heading-two"><LooksTwoIcon /></BlockButton>
          <BlockButton title="Numbered List" format="numbered-list"><FormatListNumberedIcon /></BlockButton>
          <BlockButton title="Bulleted List" format="bulleted-list"><FormatListBulletedIcon /></BlockButton>
          <BlockButton title="Left Align" format="left"><FormatAlignLeftIcon /></BlockButton>
          <BlockButton title="Center Align" format="center"><FormatAlignCenterIcon /></BlockButton>
          <BlockButton title="Right Align" format="right"><FormatAlignRightIcon /></BlockButton>
          <BlockButton title="Base Align" format="justify"><FormatAlignJustifyIcon /></BlockButton>
          <BlockButton title="Upload Image" format="image" {...rootProps}><InsertPhotoIcon /></BlockButton>
        </Toolbar>
        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder="Enter some rich text…"
          tabIndex="0"
          spellCheck
          autoFocus={false}
          onKeyDown={event => {
            try {
              for (const hotkey in HOTKEYS) {
                if (isHotkey(hotkey, event)) {
                  event.preventDefault()
                  const mark = HOTKEYS[hotkey]
                  toggleMark(editor, mark)
                }
              }
            } catch (e) {}
          }}
        />
      </Slate>
      { isDragActive ? <Paper elevation={0} sx={{position:'absolute',top:0,left:0,right:0,bottom:0,textAlign:'center',display:'flex',flexDirection:'column',justifyContent:'center'}}><Typography variant="h5">Drop files here...</Typography></Paper> : undefined }
      { uploading ? <Paper elevation={0} sx={{position:'absolute',top:0,left:0,right:0,bottom:0}}><Stack alignItems="center"><CircularProgress /></Stack></Paper> : undefined }
    </Box>
  )
}

const withImages = editor => {
  const { insertData, isVoid } = editor

  editor.isVoid = element => {
    return element.type === 'image' ? true : isVoid(element)
  }

  editor.insertData = data => {
    const text = data.getData('text/plain')
    const { files } = data

    if (text) {
      if (isImageUrl(text))
        insertImage(editor, text)
      else
        insertData(data)
    }
    else if (files && files.length > 0) {
      for (const file of files) {
        //const reader = new FileReader()
        const [mime] = file.type.split('/')
        if (mime === 'image') {
          UploadReportFile([file], (file, result) => {
            const text = { text: '' }
            const image = { type: 'image', url: result, children: [text]}
            Transforms.insertNodes(editor, image)
          }).catch(e=>e)
          //reader.addEventListener('load', () => {
          //  const url = reader.result
          //  insertImage(editor, url)
          //})

          //reader.readAsDataURL(file)
        }
      }
    }
  }

  return editor
}

const isImageUrl = url => {
  if (!url) return false
  if (!isUrl(url)) return false
  const ext = new URL(url).pathname.split('.').pop()
  return imageExtensions.includes(ext)
}

const imageInsertClick = (e, editor) => {
  if (isBlockActive(editor, 'image')) {
    Transforms.removeNodes(editor)
    e.stopPropagation()
  }
}

const insertImage = (editor, url) => {
  const text = { text: '' }
  const image = { type: 'image', url, children: [text] }
  Transforms.insertNodes(editor, image)
}

const toggleBlock = (editor, format) => {
  try {
    const isActive = isBlockActive(
      editor,
      format,
      TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
    )
    const isList = LIST_TYPES.includes(format)

    Transforms.unwrapNodes(editor, {
      match: n =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        LIST_TYPES.includes(n.type) &&
        !TEXT_ALIGN_TYPES.includes(format),
      split: true,
    })
    let newProperties
    if (TEXT_ALIGN_TYPES.includes(format)) {
      newProperties = {
        align: isActive ? undefined : format,
      }
    } else {
      newProperties = {
        type: isActive ? 'paragraph' : isList ? 'list-item' : format,
      }
    }
    Transforms.setNodes(editor, newProperties)

    if (!isActive && isList) {
      const block = { type: format, children: [] }
      Transforms.wrapNodes(editor, block)
    }
  } catch (e) {}
}

const toggleMark = (editor, format) => {
  try {
    const isActive = isMarkActive(editor, format)

    if (isActive) {
      Editor.removeMark(editor, format)
    } else {
      Editor.addMark(editor, format, true)
    }
  } catch(e) {}
}

const isBlockActive = (editor, format, blockType = 'type') => {
  const { selection } = editor
  if (!selection) return false

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format,
    })
  )

  return !!match
}

const isMarkActive = (editor, format) => {
  try {
    const marks = Editor.marks(editor)
    return marks ? marks[format] === true : false
  }catch (e) {}
}

const Element = ({ attributes, children, element }) => {
  const style = { textAlign: element.align, margin:0 }
  //const editor = useSlate()
  const selected = useSelected()
  const focused = useFocused()
  switch (element.type) {
    case 'block-quote':
      return (<blockquote style={style} {...attributes}>{children}</blockquote>)
    case 'bulleted-list':
      return (<ul style={style} {...attributes}>{children}</ul>)
    case 'heading-one':
      return (<h3 style={style} {...attributes}>{children}</h3>)
    case 'heading-two':
      return (<h4 style={style} {...attributes}>{children}</h4>)
    case 'list-item':
      return (<li style={style} {...attributes}>{children}</li>)
    case 'numbered-list':
      return (<ol style={style} {...attributes}>{children}</ol>)
    case 'image':
      //const path = ReactEditor.findPath(editor, element)
      return (
        <span style={{position: 'relative', display: 'inline-block'}}>
          {children}
          <img
            {...attributes}
            src={element.url}
            alt=""
            style={{
              display: 'inline',
              maxWidth: '100%',
              maxHeight: '17em',
              boxShadow: selected && focused ? '0 0 0 3px #B4D5FF' : 'none'
            }}
          />
        </span>
      )
/*      return (
        <div {...attributes}>
          {children}
          <div
            contentEditable={false}
            style={{
              position: 'relative'
            }}
          >
            <img
              src={element.url}
              alt=""
              style={{
                display: 'inline',
                maxWidth: '100%',
                maxHeight: '10em',
                boxShadow: selected && focused ? '0 0 0 3px #B4D5FF' : 'none'
              }}
            />
            <div 
              style={{
                display: selected && focused ? 'inline' : 'none',
                position: 'absolute',
                top: '0.5em',
                left: '0.5em'
              }}
            >
              <Button
                active
                onClick={() => Transforms.removeNodes(editor, { at: path })}
              >
                <DeleteIcon />
              </Button>
            </div>
          </div>
        </div>
      )*/
    default:
      return (<p style={style} {...attributes}>{children}</p>)
  }
}

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  return <span {...attributes}>{children}</span>
}

const BlockButton = React.forwardRef(({ format, children, ...props}, ref) => {
  const editor = useSlate()
  return (
    <Button
      ref={ref}
      active={isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
      )}
      onMouseDown={format === 'image' ? undefined : event => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
      {...props}
    >
      {children}
    </Button>
  )
})

const MarkButton = React.forwardRef(({ format, children, ...props}, ref) => {
  const editor = useSlate()
  return (
    <Button
      ref={ref}
      active={isMarkActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
      {...props}
    >
      {children}
    </Button>
  )
})

const Button = React.forwardRef(({ className, active, reversed, ...props }, ref) => (
  <span
    {...props}
    ref={ref}
    style={{
      cursor: 'pointer',
      display: 'inline-block',
      marginRight: '15px',
      color: reversed
        ? active
          ? '#fff'
          : '#aaa'
        : active
        ? '#666'
        : '#ccc'
    }}
  />
))
const Toolbar = React.forwardRef(({ className, ...props }, ref) => (
  <div
    {...props}
    ref={ref}
    style={{
      position: 'relative',
      padding: '5px 18px 10px',
      margin: '0 0',
      borderBottom: '2px solid #eee'
    }}
  />
))

export default RichText
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { setAuthenticatedCallback, CheckLogin } from './api';
import AccountAccess from './components/AccountAccess';
import Portal from './components/Portal';
import { Provider, ErrorBoundary } from '@rollbar/react';
import { LicenseInfo } from '@mui/x-license';

LicenseInfo.setLicenseKey('e6d3ff8d91aee5851130c24effe73c2dUz1wcmVtaXVtLExNPXBlcnBldHVhbCxFPTI1MzI0MDkyMDAwMDAsS1Y9Mg==');

const rollbarConfig = {
  accessToken: '5db6e4864fd14d588bf9a31a39f1cefa',
  environment: 'prod',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: "prod",
    client: {
      javascript: {
        source_map_enabled: true, // false by default
        
        // -- Add this into your configuration ---
        code_version: "1",
        // ---------------------------------------
        
        // Optionally have Rollbar guess which frames the error was 
        // thrown from when the browser does not provide line 
        // and column numbers.
        guess_uncaught_frames: true
      }
    }
  }
}

const App = () => {
  const [isAuthenticated, setAuthenticated] = useState(CheckLogin());

  useEffect(() => {
    setAuthenticatedCallback(setAuthenticated);
  }, []);

  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <Router>
          {isAuthenticated ?
            <Routes>
              <Route path="*" element={<Portal />} />
            </Routes> :
            <Routes>
              <Route path="*" element={<AccountAccess setAuthenticated={setAuthenticated} />} />
            </Routes>
          }
        </Router>
      </ErrorBoundary>
    </Provider>
  );
}

export default App;

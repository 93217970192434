import React, { useState, useEffect } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Routes, Route } from "react-router-dom";
import { GetUser, Logout } from "../../api";
import DashWrapper from "./DashWrapper";
import SettingsWrapper from "./SettingsWrapper";
import { setPushAlert, onSocketStatus } from "../../api"

const theme = createTheme({
  palette: {
    mode: "dark",
  },
  typography: {
    button: {
      fontWeight: 400
    },
    h1: {
      fontWeight: 100
    },
    h2: {
      fontWeight: 300
    },
    h3: {
      fontWeight: 300
    },
    h4: {
      fontWeight: 300
    },
    h5: {
      fontWeight: 300
    },
    h6: {
      fontWeight: 300
    },
  },
})

const Portal = () => {
  const [user, setUser] = useState()
  const [access, setAccess] = useState()
  const [alerts, setAlerts] = useState([])
  const [socketStatus, setSocketStatus] = useState()
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const pushAlertCallback = alert => setAlerts(a => [...a, alert])
  const socketStatusCallback = status => setSocketStatus(status)

  useEffect(() => {
    pushAlertCallback && setPushAlert(pushAlertCallback)
      socketStatusCallback && onSocketStatus(socketStatusCallback)
      return () => { setPushAlert(); onSocketStatus() }
    }
  , [])
  
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    GetUser().then(resp => {
      if (!resp) Logout()
      if (!resp.org) {
        resp.org = {id: 0, name: '[No Organization Assigned]'}
        resp.access = [{org: {id: 0, name: '[No Organization Assigned]'}, clinic: false, tele: false}]
      }
      if (!(resp.access && resp.access.length))
        resp.access = [{clinic: true, tele: false, org: {...resp.org}}]
      
      if (resp.access.length > 1)
        resp.access.sort((a,b) => {
          const c = a.org.name.toUpperCase()
          const d = b.org.name.toUpperCase()
          if (c < d) return -1
          if (c > d) return 1
          return 0
        })
      setUser(resp)
      setAccess(resp.access && resp.access.find(e => resp.org.id === e.org.id))
    }).catch(e => Logout())
  }, [])

  if (user && access)
    return (
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/settings/*" element={<SettingsWrapper user={user} access={access} setUser={setUser} setAccess={setAccess} mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />} />
          <Route path="*" element={<DashWrapper user={user} access={access} setAccess={setAccess} mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} alerts={alerts} setAlerts={setAlerts} socketStatus={socketStatus} />} />
        </Routes>
      </ThemeProvider>
    )

  return <React.Fragment />;
};

export default Portal;

import React, { useMemo } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import Divider from "@mui/material/Divider";
import DashboardIcon from "@mui/icons-material/Dashboard";
import UploadIcon from "@mui/icons-material/Upload";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import MedicalInformationIcon from "@mui/icons-material/MedicalInformation";
import MessageIcon from '@mui/icons-material/Message';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { Link, useLocation } from "react-router-dom";
import StudiesSubNavigation from "./StudiesSubNavigation";
import TeleconsultationSubNavigation from "./TeleconsultationSubNavigation";

const SideNavBar = ({ handleDrawerToggle, mobileOpen, drawerWidth, user, access }) => {

  const location = useLocation();

  const locationMatch = useMemo(() => {
    const i = location.pathname.toLowerCase().substring(1).split('/')
    while (i.length < 3) i.push(null)
    return i
  }, [location.pathname])

  const { org } = access

  const drawer = (
    <List>
      <ListItem key="Dashboard" disablePadding>
        <ListItemButton component={Link} to="/" selected={locationMatch[0] === ''}>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItemButton>
      </ListItem>
      {access.clinic && (
        <>
          <Divider />
          <ListItem key="Studies" disablePadding>
            <ListItemButton component={Link} to={`/${org.id}/clinic`} selected={locationMatch[2] === null && locationMatch[1] === 'clinic'}>
              <ListItemIcon>
                <LibraryBooksIcon />
              </ListItemIcon>
              <ListItemText primary="Studies" />
            </ListItemButton>
          </ListItem>
          <StudiesSubNavigation open={true} org={org} active={locationMatch[1] === 'clinic' && locationMatch[2]} />
        </>
      )}
      {access.consult && (
        <>
          <Divider />
          <ListItem key="Teleconsultation" disablePadding>
            <ListItemButton component={Link} to={`/${org.id}/specialist`} selected={locationMatch[2] === null && locationMatch[1] === 'specialist'}>
              <ListItemIcon>
                <MedicalInformationIcon />
              </ListItemIcon>
              <ListItemText primary="Teleconsultation" />
            </ListItemButton>
          </ListItem>
          <TeleconsultationSubNavigation open={true} org={org} active={locationMatch[1] === 'specialist' && locationMatch[2]} />
        </>
      )}
      <Divider />
      <ListItem key="New Consult" disablePadding>
        <ListItemButton component={Link} to="/new-consult" selected={locationMatch[0] === 'new-consult'}>
          <ListItemIcon>
            <MessageIcon />
          </ListItemIcon>
          <ListItemText primary="Request Consult" />
        </ListItemButton>
      </ListItem>
      <ListItem key="New Study" disablePadding>
        <ListItemButton component={Link} to="/new" selected={locationMatch[0] === 'new'}>
          <ListItemIcon>
            <NoteAddIcon />
          </ListItemIcon>
          <ListItemText primary="Non-DICOM Study" />
        </ListItemButton>
      </ListItem>
      <ListItem key="Import Study" disablePadding>
        <ListItemButton component={Link} to="/import" selected={locationMatch[0] === 'import'}>
          <ListItemIcon>
            <UploadIcon />
          </ListItemIcon>
          <ListItemText primary="DICOM Study" />
        </ListItemButton>
      </ListItem>
    </List>
  )

  return (
    <>
      <CssBaseline />
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              position: "fixed",
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          position="static"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              position: "static",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </>
  );
};

export default SideNavBar;

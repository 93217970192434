import React, { useState, useCallback, useEffect } from "react";
import {
  Container,
  Grid,
  Button,
  FormControl,
  Typography,
  TextField,
  MenuItem
} from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import LockIcon from '@mui/icons-material/Lock';
import { useNavigate } from 'react-router-dom';
import { UpdateUser, Logout } from '../../../../api'


const UserSettings = ({ user, setUser }) => {

    const [ passValues , setPassValues ] = useState({})
    const [ userValues , setUserValues ] = useState({})
    const [ submitError, setSubmitError ] = useState()

    const navigate = useNavigate();

    const updatePass = e => {
        setPassValues(p => ({...p, [e.target.name]: e.target.value}))
    }

    const updateUser = e => {
        setUserValues(p => ({...p, [e.target.name]: e.target.value}))
    }

    const handlePassSubmit = useCallback(e => {
        e.preventDefault()
        if (passValues.password.length <= 5)
            setSubmitError('New password must be at least 5 characters')
        else if (passValues.password === passValues.confirmPassword)
            UpdateUser(null, passValues).then(() => Logout()).then(() => navigate('/')).catch(e => {
                const error = (e.response && e.response.data && e.response.data.errors) || e.message
                setSubmitError(error)
            })
        else
            setSubmitError('New password and confirm password do not match')
    }, [passValues, navigate])

    const handleUserSubmit = useCallback(e => {
        e.preventDefault()
        UpdateUser(null, userValues).then(() =>
            setUser(e => ({...e, email: userValues.email, name: userValues.name, suffix: userValues.suffix}))
        ).then(() => navigate('/'))
    }, [userValues, navigate, setUser])

    useEffect(() => {
        setUserValues({email: user.email, name: user.name, suffix: user.suffix, org: user.org.id})
    }, [user])

    return (
        <Container>
            <form onSubmit={handlePassSubmit} noValidate>
                <Grid
                    container
                    sx={{ mb: 3 }}
                    spacing={3}
                    justifyContent="left"
                    maxWidth="sm"
                >
                    <Typography component="h1" variant="h4">Update Password</Typography>
                    {submitError ? <FormControl sx={{mb: 1}} fullWidth variant="standard"><Typography component="p" color="error">{submitError}</Typography></FormControl> : null}
                    <FormControl sx={{mb: 1}} fullWidth variant="standard">
                        <TextField
                            name="oldPassword"
                            value={passValues.oldPassword || ''}
                            label="Current Password"
                            onChange={updatePass}
                            variant="standard"
                            type="password"
                        />
                    </FormControl>
                    <FormControl sx={{mb: 1}} fullWidth variant="standard">
                        <TextField
                            name="password"
                            value={passValues.password || ''}
                            label="New Password"
                            onChange={updatePass}
                            variant="standard"
                            type="password"
                        />
                    </FormControl>
                    <FormControl sx={{mb: 1}} fullWidth variant="standard">
                        <TextField
                            name="confirmPassword"
                            value={passValues.confirmPassword || ''}
                            label="Confirm New Password"
                            onChange={updatePass}
                            variant="standard"
                            type="password"
                        />
                    </FormControl>
                </Grid>
                <Grid sx={{mb: 5}} container spacing={3} justifyContent="right" maxWidth="sm">
                    <Grid item>
                        <Button variant="outlined" color="primary" type="submit" startIcon={<LockIcon />}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <form onSubmit={handleUserSubmit} noValidate>
                <Grid
                    container
                    sx={{ mb: 3 }}
                    spacing={3}
                    justifyContent="left"
                    maxWidth="sm"
                >
                    <Typography component="h1" variant="h4">Update User Profile</Typography>
                    <FormControl sx={{mb: 1}} fullWidth variant="standard">
                        <TextField
                            name="email"
                            value={userValues.email || ''}
                            label="Email"
                            onChange={updateUser}
                            variant="standard"
                        />
                    </FormControl>
                    <FormControl sx={{mb: 1}} fullWidth variant="standard">
                        <TextField
                            name="name"
                            value={userValues.name || ''}
                            label="Name"
                            onChange={updateUser}
                            variant="standard"
                        />
                        <TextField
                            name="suffix"
                            value={userValues.suffix || ''}
                            label="Suffix"
                            onChange={updateUser}
                            variant="standard"
                        />
                        <TextField
                            name="org"
                            value={userValues.org || ''}
                            label="Default Organization"
                            onChange={updateUser}
                            variant="standard"
                            select
                        >
                            {user.access.map(e => 
                                <MenuItem key={e.org.id} value={e.org.id}>
                                    {e.org.name}
                                </MenuItem>
                            )}
                        </TextField>
                    </FormControl>
                </Grid>
                <Grid container spacing={3} justifyContent="right" maxWidth="sm">
                    <Grid item>
                        <Button variant="outlined" color="primary" type="submit" startIcon={<SaveIcon />}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Container>
    )

}

export default UserSettings
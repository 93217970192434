import React from "react";
import { Link } from "react-router-dom";
import { AppBar, Toolbar, Typography, IconButton } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuIcon from "@mui/icons-material/Menu";
import SelectOrg from "./SelectOrg";
import SubMenu from "./SubMenu";

const TopNavBar = ({ handleDrawerToggle, user, access, setAccess }) => {
  const matches = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2, display: { sm: "none" } }}
          onClick={handleDrawerToggle}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <Link to="/" style={{color: "#fff", textDecoration: "none"}}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox={(matches && "0 0 150 92") || "0 0 580 92"}
              width={(matches && "40") || "221"}
              height="35"
              aria-labelledby="corridorLogoTitle"
              role="img"
            >
              <title id="corridorLogoTitle">CorridorVet logo</title>
              <g fill="#fff">
                <path d="m45.24 2.6h2.14l0.12-0.07 41.67 76.59 39.52-72.45h-24.73v-5.66h29.4 4.66l-2.15 4.13-42.54 78.25-4.15 8.12-45-83.24c-10.47 0.28-19.96 4.68-26.91 11.62-7.17 7.18-11.63 17.08-11.63 27.96 0 10.89 4.46 19.2 11.63 26.37 7.18 7.18 17.08 11.63 27.97 11.63h35.54l3.06 5.66h-38.6c-12.45 0-23.77-5.08-31.97-13.29-8.2-8.2-13.29-17.92-13.29-30.37 0-12.44 5.09-23.76 13.29-31.96 8.2-8.2 19.52-13.29 31.97-13.29z"/>
              </g>
              <g fill="#fff">
                <path d="m158.7 0.7h2v89.3h-2z"/>
              </g>
              <g fill="#fff">
                <path d="m182.5 47.5c0-15.4 10.6-26.2 25.9-26.2 7 0 13.3 2.7 18.1 7.7l-3 2.7c-3.9-4.1-9.2-6.3-15-6.3-12.8 0-21.8 9-21.8 22.1 0 13 9 22 21.8 22 5.8 0 11.3-2.2 15.4-6.3l2.9 2.9c-4.9 4.8-11.3 7.5-18.4 7.5-15.3 0-25.9-10.8-25.9-26.1z"/>
                <path d="m232.4 54.9c0-10.7 7.5-18.5 18.3-18.5 10.8 0 18.4 7.8 18.4 18.5 0 10.6-7.6 18.5-18.4 18.5-10.8 0-18.3-7.9-18.3-18.5zm32.6 0c0-8.3-5.9-14.6-14.3-14.6-8.4 0-14.2 6.3-14.2 14.6 0 8.3 5.8 14.6 14.2 14.6 8.4 0 14.3-6.3 14.3-14.6z"/>
                <path d="m301.6 38.8l-1.8 3.6q-2.8-1.8-6.1-1.8c-6.6 0-11.4 6-11.4 14v18.1h-4.1v-35.6h3.7l0.1 7.4c2.5-5 6.8-7.8 11.9-7.8 2.8 0 5.7 0.8 7.7 2.1z"/>
                <path d="m331 38.8l-1.8 3.6q-2.8-1.8-6-1.8c-6.7 0-11.5 6-11.5 14v18.1h-4.1v-35.6h3.8v7.4c2.6-5 6.8-7.8 11.9-7.8 2.9 0 5.7 0.8 7.7 2.1z"/>
                <path d="m335.8 26.1c0-1.8 1.4-3.1 3.3-3.1 1.9 0 3.3 1.3 3.3 3.1 0 1.9-1.4 3.3-3.3 3.3-1.9 0-3.3-1.4-3.3-3.3zm1.3 11h4.1v35.6h-4.1z"/>
                <path d="m386.6 22.3v50.4h-3.8v-7.1c-2.7 4.8-8.2 7.7-14.8 7.7-10.3 0-17.8-7.5-17.8-18.4 0-11 7.5-18.5 17.8-18.5 6.4 0 11.7 2.9 14.5 7.4v-21.5c0 0 4.1 0 4.1 0zm-4.1 32.6c0-8.8-5.8-14.6-14.1-14.6-8.2 0-14.1 5.8-14.1 14.6 0 8.8 5.9 14.6 14.1 14.6 8.3 0 14.1-5.8 14.1-14.6z"/>
                <path d="m395.7 54.9c0-10.7 7.5-18.5 18.4-18.5 10.8 0 18.4 7.8 18.4 18.5 0 10.6-7.6 18.5-18.4 18.5-10.9 0-18.4-7.9-18.4-18.5zm32.7 0c0-8.3-5.9-14.6-14.3-14.6-8.4 0-14.3 6.3-14.3 14.6 0 8.3 5.9 14.6 14.3 14.6 8.4 0 14.3-6.3 14.3-14.6z"/>
                <path d="m464.9 38.8l-1.8 3.6q-2.8-1.8-6-1.8c-6.6 0-11.5 6-11.5 14v18.1h-4.1v-35.6h3.8l0.1 7.4c2.5-5 6.7-7.8 11.8-7.8 2.9 0 5.7 0.8 7.7 2.1z"/>
                <path d="m512.8 22.3l-20.7 50.4h-4.8l-20.9-50.4h4.7l18.6 45.4 18.5-45.4z"/>
                <path d="m550.2 56.5h-31.7c0.7 8.1 6.1 13.1 14.1 13.1 5 0 10-2.1 12.5-5.3l2.8 2.5c-3.4 4.1-9.1 6.7-15.4 6.7-10.7 0-18.2-7.7-18.2-18.6 0-10.8 7.3-18.5 18-18.5 11.7 0 18.9 8 17.9 20.1zm-31.7-3.6h27.7c-0.1-7.7-5.7-12.7-13.9-12.7-7.8 0-13 5-13.8 12.7z"/>
                <path d="m579.9 71.4c-2.4 1.2-4.9 1.9-7.3 1.9-7.5 0-10.2-4.2-10.2-11.7v-20.8h-7.5v-3.7h7.5v-10.2l4.1-0.5v10.7h11.8v3.7h-11.8v20.8c0 4.7 1.1 8 6.3 8 1.7 0 4-0.8 5.8-1.6z"/>
              </g>
            </svg>
            <Typography sx={{display:(matches && 'none') || 'inline',fontSize:24,verticalAlign:'top',fontWeight:100}}>&trade;</Typography>
          </Link>
        </Typography>
        <SelectOrg user={user} access={access} setAccess={setAccess} />
        <SubMenu name={user.name} />
      </Toolbar>
    </AppBar>
  );
};

export default TopNavBar;

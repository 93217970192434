import React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

let key = 0

const Alerts = ({ alerts, setAlerts }) => {

  const removeAlert = i =>
    setAlerts(a => {
      const ac = [...a]
      ac.splice(i,1)
      return ac
    })

  return (
    <Stack sx={{ width: '100%' }} spacing={2}>
      {alerts && !!alerts.length && alerts.slice(-3).map((alert, i) =>
        <Alert  variant="outlined" key={key++} severity={alert.severity} onClose={() => removeAlert(i)}>{`${alert.timestamp?.toLocaleString()}: ${alert.message}`}</Alert>
      )}
    </Stack>
  )
}

export default Alerts
import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { TextField, MenuItem } from "@mui/material";

const SelectOrg = ({ user, access, setAccess }) => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const loc = location.pathname.match(/^\/([a-z0-9]{24,})\/(clinic|specialist)/i)
    if (loc) {
      const currentOrgId = loc[1];
      if (currentOrgId !== access.org.id) {
        const pathNameArray = location.pathname.split("/")
        if ((loc[2] === 'clinic' && !access.clinic) || (loc[2] === 'specialist' && !access.consult))
          navigate('/', { replace: true })
        else {
          pathNameArray.splice(1, 1, access.org.id);
          const newUrl = pathNameArray.join("/");
          navigate(newUrl, { replace: true })
        }
      }
    }
  }, [location, access, navigate])

  const handleChange = e => setAccess(user.access.find(i=>i.org.id===e.target.value))

  return (
    <TextField
      id="org"
      defaultValue={user.org.id}
      label="Organization"
      onChange={handleChange}
      size="small"
      variant="standard"
      sx={{mr: 2, minWidth: 300}}
      select
    >
      {user.access.filter(e => e.clinic || e.consult).map(e => (
        <MenuItem key={e.org.id} value={e.org.id}>
          {e.org.name}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default SelectOrg;

import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import { GetOrg, AssignStudy as assignStudy } from '../../../../api'

export default function AssignStudy({dialogState, access}) {

    const [requestees, setRequestees] = useState();
    const [requestee, setRequestee] = useState('');

    useEffect(() => {
        if (access?.org?.users?.length && access?.org?.id)
            GetOrg(access.org.id).then(resp => resp?.users?.length && setRequestees(resp.users.map(e=>({id: e.id, name: e.name}))))
    }, [access]);

    useEffect(() => {
        dialogState.open && setRequestee('')
    }, [dialogState.open])

    const onSend = () => {
        if (requestee) {
            assignStudy(dialogState.studyId, {requestee}).then(() => dialogState.onTransferComplete && dialogState.onTransferComplete(dialogState)).catch(e=>e)
            dialogState.onSend(dialogState)
        }
        else dialogState.onClose(dialogState)
    }

    return (
        <Dialog open={dialogState.open || false} onClose={dialogState.onClose} fullWidth maxWidth="xs">
            <DialogTitle>Assign Study</DialogTitle>
            <DialogContent>
            {requestees && (
                <FormControl sx={{mb: 1}} fullWidth variant="standard">
                    <TextField
                    id="requestee"
                    value={requestee || ''}
                    label="Requestee"
                    onChange={e => setRequestee(e.target.value)}
                    variant="standard"
                    select
                    required
                    >
                    {requestees.map((e) => (
                        <MenuItem key={e.id} value={e.id}>
                        {e.name}
                        </MenuItem>
                    ))}
                    </TextField>
                </FormControl>
            )}
            </DialogContent>
            <DialogActions>
                <Button onClick={dialogState.onClose}>Cancel</Button>
                <Button onClick={onSend}>Assign</Button>
            </DialogActions>
        </Dialog>
    );
}
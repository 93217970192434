export const GENDER_OPTIONS = [
  { key: "Male", val: 'M' },
  { key: "Male Castrated", val: 'MN' },
  { key: "Female", val: 'F' },
  { key: "Female Spayed", val: 'FS' },
];

export const SPECIES_OPTIONS = [
  "Amphibian",
  "Avian",
  "Bovine",
  "Canine",
  "Caprine",
  "Equine",
  "Feline",
  "Lagomorph",
  "Mustelidae",
  "Ovine",
  "Porcine",
  "Other"
];

export const PRIORITIES = [
  {id: '', name: ''},
  {id: 0, name: 'Standard'},
  {id: 1, name: 'STAT', hidden: 1},
  {id: 5, name: 'Priority'},
  {id: 10, name: 'STAT'},
]
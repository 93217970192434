import React, { useState, useCallback, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { Typography, Box, Grid, List, ListItem, ListItemIcon, ListItemText, ListSubheader, Tooltip } from '@mui/material';
import TaskIcon from '@mui/icons-material/Task';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import FileDownloadOffIcon from '@mui/icons-material/FileDownloadOff';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import CircularProgress from '@mui/material/CircularProgress';
import { UploadInstance } from '../../../../../api';

const parsePersonName = (name) => name.replace(/^\^*(.*?)\^*$/, '$1').replace(/\^+/, ', ');

const Import = ({ access }) => {

  const [parsing, setParsing] = useState(false);
  const [studies, setStudies] = useState({});
  const studiesRef = useRef();

  studiesRef.current = studies;

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length) {
      const dicomParser = require('dicom-parser');

      const createStudy = (dicomData, ...file) => {
        let study, copyStudies = {...studiesRef.current};
        if (dicomData.studyUid in copyStudies) {
          study = {...copyStudies[dicomData.studyUid]};
        }
        else
          study = Object.assign(dicomData, {files: [], totalFiles: 0});
        
        if (file.length) {
          let files = [...study.files];
          files.push(file[0]);
          study.files = files;
        }
        else
          study.totalFiles++;

        copyStudies[dicomData.studyUid] = study;
        setStudies(studiesRef.current = copyStudies);
        
      }
      // Parse DICOMS
      setParsing(100);
      let filesParsed = 0;
      const filesParsedUpdate = () => {
        const ratio = ++filesParsed / acceptedFiles.length;
        setParsing(ratio === 1 ? false : Math.round(ratio * 100));
      }
      let promises = [];
      acceptedFiles.map(acceptedFile =>
        promises.push(new Promise(resolve => {
          const reader = new FileReader();
          reader.onload = file => {
            var arrayBuffer = reader.result;
            // Here we have the file data as an ArrayBuffer.  dicomParser requires as input a
            // Uint8Array so we create that here
            var byteArray = new Uint8Array(arrayBuffer);

            try {
              const dataSet = dicomParser.parseDicom(byteArray);
              acceptedFile.dicomData = {
                studyUid: dataSet.string('x0020000d'),
                studyDesc: dataSet.string('x00081030'),
                patientName: dataSet.string('x00100010')
              };
            } catch (e) {
              console.log(e);
              acceptedFile.dicomData = {
                studyUid: 'Unknown',
                studyDesc: 'Unknown',
                patientName: 'Unknown'
              };
            }
            createStudy(acceptedFile.dicomData);

            filesParsedUpdate();
            resolve();
          }
          reader.readAsArrayBuffer(acceptedFile);
        }))
      );
      // Upload files
      Promise.all(promises).then(() =>
        UploadInstance(acceptedFiles, {org: access.org.id} , (file, result) => {
          if ('Status' in result)
            switch (result.Status) {
              case 'AlreadyStored':
                createStudy(file.dicomData, {name: file.name, icon: ContentCopyIcon, status: 'Already Exists'})
                break;
              case 'Success':
                createStudy(file.dicomData, {name: file.name, icon: FileDownloadDoneIcon, status: 'Success'})
                break;
              default:
                createStudy(file.dicomData, {name: file.name, icon: QuestionMarkIcon, status: result.Status})
            }
          else {
            createStudy(file.dicomData, {name: file.name, icon: FileDownloadOffIcon, status: 'Unknown Error'})
          }
        }).catch(e=>e)
      )
    }
  }, [access]);
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
  const UploadList = () => {
    if (Object.keys(studies).length)
      return (
        <List subheader={<ListSubheader>Uploads</ListSubheader>}>
          {Object.entries(studies).map(([key, study]) => {
            const percent = Math.round(study.files.length / study.totalFiles * 100);
            return (
              <ListItem key={key}>
                <ListItemIcon>{percent === 100 ? <TaskIcon /> : percent === 0 ? <CircularProgress /> : <CircularProgress variant="determinate" value={percent} /> }</ListItemIcon>
                <ListItemText
                  primary={study.studyDesc === 'Unknown' ? study.studyDesc : `${study.studyDesc ? study.studyDesc : ''} ${study.patientName ? parsePersonName(study.patientName) : ''}`.trim()}
                  secondary={
                    <>
                      {study.files.map((file, i) => <Tooltip title={file.status} key={i}>{file.icon ? React.createElement(file.icon) : undefined}</Tooltip>)}
                    </>
                  }
                />
              </ListItem>
            )
          })}
          <ListItemIcon></ListItemIcon>
        </List>
      )
    return (<React.Fragment />);
  }

  return (
    <Box>
      { parsing ? 
        <Grid container align="center" justify="center" direction="column" spacing={3}>
          <Grid item xs={1}>
            { parsing >= 100 ? <CircularProgress /> : <CircularProgress variant="determinate" value={parsing} /> }
          </Grid>
          <Grid item xs={3}>
            <Typography>Parsing DICOM files...</Typography>
          </Grid>
        </Grid>
      :
        <Box p={20} {...getRootProps()}>
          <input {...getInputProps({})} />
          {
            isDragActive ?
              <Typography align="center">Drop the files here ...</Typography> :
              <Typography align="center">Drop files here, or click to select files</Typography>
          }
        </Box>
      }
      <UploadList />
    </Box>
  );
}

export default Import;

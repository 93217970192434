import React, { useState, useCallback } from "react";
import { useDropzone } from 'react-dropzone';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import CircularProgress from '@mui/material/CircularProgress';
import {
    Box,
    List,
    ListItem,
    IconButton,
    ListItemText,
    ListItemIcon,
    Button,
    FormControl,
    Grid,
    Typography,
    Paper,
    Stack
  } from "@mui/material";
import { UploadReportFile } from '../../../../api';

export const getFilename = file => {
    const filename = file.split('/').pop()
    const fileSuffix = filename.match(/^(?:[^-]+-)(.*)/)
    return decodeURI(fileSuffix && fileSuffix.length === 2 ? fileSuffix[1] : filename)
}

const FileDrop = ( { media, setMedia }) => {

    const [uploading, setUploading] = useState(false)

    const onDrop = useCallback(async acceptedFiles => {
        if (acceptedFiles.length) {
            setUploading(true)
            await UploadReportFile(acceptedFiles, (file, result) => setMedia(m => m.push(result) && m)).catch(e=>e)
            setUploading(false)
        }
    }, [setMedia])

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

    const deleteMediaIndex = i => {
        setMedia(m => {
            const mediaCopy = [...m]
            mediaCopy.splice(i, 1)
            return mediaCopy
        })
    }

    return (
        <Box sx={{position:'relative'}}>
            <input {...getInputProps({})} />
            <Grid container sx={{mb:3}}>
                <Grid item md={5}>
                    <List>
                        {media.map((file, index) =>
                            <ListItem key={file} secondaryAction={
                                <IconButton edge="end" aria-label="delete" onClick={() => deleteMediaIndex(index)}>
                                <DeleteIcon />
                            </IconButton>
                            }>
                                <ListItemIcon>
                                    <FilePresentIcon />
                                </ListItemIcon>
                                <ListItemText sx={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}} primary={getFilename(file)} />
                            </ListItem>
                        )}
                    </List>
                </Grid>
                { isDragActive ? <Paper elevation={0} sx={{position:'absolute',top:0,left:0,right:0,bottom:0,textAlign:'center',display:'flex',flexDirection:'column',justifyContent:'center'}}><Typography variant="h5">Drop files here...</Typography></Paper> : undefined }
                { uploading ? <Paper elevation={0} sx={{position:'absolute',top:0,left:0,right:0,bottom:0}}><Stack alignItems="center"><CircularProgress /></Stack></Paper> : undefined }
            </Grid>
            <Grid container sx={{ mb: 3 }} spacing={3} justifyContent="center">
                <FormControl fullWidth variant="standard">
                    <Button color="secondary" startIcon={<AttachFileIcon />} {...getRootProps()}>
                    Add Multimedia
                    </Button>
                </FormControl>
            </Grid>
        </Box>
    )
  }

  export default FileDrop;
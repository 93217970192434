import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, TextField, Button, Grid, Box, Typography, Link as LinkText } from '@mui/material';
import { Login as ApiLogin } from '../../../api';
import VerifyCode from '../CreateAccount/VerifyCode';
import { validate } from 'validate.js';
import constraints from '../validate-email-constraints';
import { red } from '@mui/material/colors';

const Login = ({ setAuthenticated }) => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [submitError, setSubmitError] = useState(false);
  const [verifyStage, setVerifyStage] = useState(false);

  const handleSubmit = async e => {
    const emailAddress = email.toLowerCase().trim()
    e.preventDefault();
    setSubmitError(false);
    const validation = validate({emailAddress}, constraints);

    if (validation)
      setSubmitError(validation.emailAddress)
    else
      ApiLogin(emailAddress, password).then(() => {
        setAuthenticated(true);
      }).catch(e => {
        const error = (e.response && e.response.data && e.response.data.errors) || e.message
        if (error === 'User is not verified')
          setVerifyStage(true);
        else
          setSubmitError(error);
      });
  }


  if (verifyStage)
    return (
      <VerifyCode setAuthenticated={setAuthenticated} email={email} password={password} />
    );

  return (
    <Container>
      <Typography component="h1" variant="h4">Login</Typography>
      {submitError ? <Typography component="p" color="error">{submitError}</Typography> : null}
      <form onSubmit={handleSubmit}>
        <TextField sx={{marginTop: 1}} value={email} name="email" fullWidth={true} required={true} label="Email address" id="email" onChange={e => setEmail(e.target.value)} />
        <TextField sx={{marginTop: 1}} value={password} name="password" type="password" fullWidth={true} required={true} label="Password" id="password" onChange={e => setPassword(e.target.value)} />
        <Grid container justifyContent="center">
          <Box mt={5} mb={5}>
            <Button variant="contained" color="primary" type="submit">Log in</Button>
          </Box>
        </Grid>
        <Grid container justifyContent="center">
          <Typography>Don't have an account? <Link to="/create-account"><LinkText component="span" sx={{color: red[500]}}>Sign up!</LinkText></Link></Typography>
        </Grid>
      </form>
    </Container>
  );
}

export default Login;

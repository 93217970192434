import React, { useState } from 'react';
import { Container, TextField, Button, Grid, Box, Typography } from '@mui/material';
import { VerifyUser, Login, VerifyResend } from '../../../../api';
import { useNavigate } from 'react-router-dom';

const VerifyCode = ({ setAuthenticated, email, password }) => {

  const [submitError, setSubmitError] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');

  const navigate = useNavigate();

  const handleSubmit = async e => {
    e.preventDefault();
    setSubmitError(false);

    VerifyUser(email, verificationCode).then(() => {
      Login(email, password).then(() => {
        setAuthenticated(true);
        navigate('/');
      }).catch(e => {
        const error = (e.response && e.response.data && e.response.data.errors) || e.message
        setSubmitError(error);
      });
    }).catch(e => {
      const error = (e.response && e.response.data && e.response.data.errors) || e.message
      setSubmitError(error);
    });
  }

  const resendCode = async () => {
    setSubmitError(false);
    VerifyResend(email).catch(e => {
      const error = (e.response && e.response.data && e.response.data.errors) || e.message
      setSubmitError(error);
    })
  }

  return (
    <Container>
      <Typography component="h1" variant="h4">Verify Account</Typography>
      <Typography component="p" color="error">{submitError ? submitError : 'Please check your email for a verification code'}</Typography>
      <form onSubmit={handleSubmit}>
        <TextField value={verificationCode} name="code" fullWidth={true} required={true} label="Verification Code" id="code" onChange={e => setVerificationCode(e.target.value)} />
        <Grid container justifyContent="center">
          <Box mt={3} mr={1}>
            <Button variant="contained" color="primary" type="submit">Verify</Button>
          </Box>
          <Box mt={3}>
            <Button variant="contained" onClick={e => resendCode(e.target.value)}>Resend Code</Button>
          </Box>
        </Grid>
      </form>
    </Container>
  );
}

export default VerifyCode;
import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import { ReOrg } from '../../../../api'

export default function TransferDialog({dialogState, user}) {

    const [orgs, setOrgs] = useState();
    const [org, setOrg] = useState();

    useEffect(() => {
        const consultOrgs = user.access && user.access.filter(e => e.consult || e.transfer)
        setOrgs(consultOrgs)

    }, [user.access, dialogState.orgId]);

    useEffect(() => {
        dialogState.orgId && setOrg(dialogState.orgId)
    }, [dialogState])

    const onSend = () => {
        ReOrg(dialogState.studyId, {org}).then(() => dialogState.onTransferComplete && dialogState.onTransferComplete(dialogState, org)).catch(e=>e)
        dialogState.onSend && dialogState.onSend(dialogState, org)
    }

    return (
        <Dialog open={dialogState.open || false} onClose={dialogState.onClose} fullWidth maxWidth="xs">
            <DialogTitle>Transfer Study</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{mb:2}} color="secondary">Billing codes will be lost in the transfer</DialogContentText>
                {orgs && (
                    <FormControl sx={{mb: 1}} fullWidth variant="standard">
                        <TextField
                        id="org"
                        value={org || ''}
                        label="Organization"
                        onChange={e => setOrg(e.target.value)}
                        variant="standard"
                        select
                        required
                        >
                        {orgs.map((e) => (
                            <MenuItem key={e.org.id} value={e.org.id}>
                            {e.org.name}
                            </MenuItem>
                        ))}
                        </TextField>
                    </FormControl>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={dialogState.onClose}>Cancel</Button>
                <Button onClick={onSend}>Transfer</Button>
            </DialogActions>
        </Dialog>
    );
}
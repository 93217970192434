import React, { useState, useEffect, useCallback } from 'react';
import { Container, Grid, TextField, Button, FormControl } from '@mui/material';
import { useParams, useNavigate } from "react-router-dom";
import { GetStudy, UpdateStudy } from '../../../../../../api';

const StudyEdit = () => {
  
  const params = useParams();
  const [study, setStudy] = useState(false);
  const navigate = useNavigate();

  const goBack = useCallback(num => {
    if (window.history.state && window.history.state.idx > 0) navigate(num)
    else navigate('/', { replace: true })
  }, [navigate])

  useEffect(() => {

    GetStudy(params.studyId).then(resp => setStudy(resp)).catch(e=>e)

  }, [params.studyId]);

  if (!study) return (<React.Fragment />);

  const handleSubmit = e => {

    e.preventDefault();
    UpdateStudy(study.id, study).then(() => goBack(-1)).catch(e=>e)

  }

  const updateStudy = e => {

    setStudy(prev => {
      let p = {...prev},
        obj = e.target.name.split('.');

      if (obj.length > 1)
        if (obj[0] in p)
          p[obj[0]] = {...p[obj[0]], [obj[1]]: e.target.value};
        else
          p[obj[0]] = {[obj[1]]: e.target.value}
      else
        p[obj[0]] = e.target.value;

      return p;

    });

  }

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <Grid sx={{mb:3}} container spacing={2} justifyContent="left" maxWidth="sm">
          <Grid item sm={12}>
            <FormControl fullWidth  variant="standard">
              <TextField name="PatientMainDicomTags.PatientName" label="Patient Name" value={study.PatientMainDicomTags.PatientName} onChange={updateStudy} />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth  variant="standard">
              <TextField name="PatientMainDicomTags.PatientID" label="Patient ID" value={study.PatientMainDicomTags.PatientID} onChange={updateStudy} />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth  variant="standard">
              <TextField name="PatientMainDicomTags.ResponsiblePerson" label="Owner Name" value={study.PatientMainDicomTags.ResponsiblePerson} onChange={updateStudy} />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth  variant="standard">
              <TextField name="MainDicomTags.StudyDescription" label="Study Description" value={study.MainDicomTags.StudyDescription} onChange={updateStudy} />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth  variant="standard">
              <TextField name="MainDicomTags.AccessionNumber" label="Accession Number" value={study.MainDicomTags.AccessionNumber} onChange={updateStudy} />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth  variant="standard">
              <TextField name="MainDicomTags.InstitutionName" label="Institution Name" value={study.MainDicomTags.InstitutionName} onChange={updateStudy} />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth  variant="standard">
              <TextField name="MainDicomTags.ReferringPhysicianName" label="Referring Physician" value={study.MainDicomTags.ReferringPhysicianName} onChange={updateStudy} />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={3} justifyContent="left">
          <Grid item>
            <Button variant="contained" color="primary" type="submit">Save</Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" color="primary" onClick={() => goBack(-1)}>Cancel</Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}

export default StudyEdit;

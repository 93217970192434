import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ListItemText from '@mui/material/ListItemText';
import Skeleton from '@mui/material/Skeleton';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import dayjs from 'dayjs';
import pLimit from 'p-limit';
import { GetStudy, GetInstanceThumb, GetReportHtml } from '../../../../api'
import { getFilename } from './fileDrop'

const limit = pLimit(2);

const SkeletonList = props =>
  <Box sx={{width: 300}}>
    <Skeleton />
    <Skeleton />
    <Skeleton />
  </Box>

export default function DetailPanelContent ({ org: orgId, row, setPdfDialogState, medDream }) {

    const [ thumbs, setThumbs ] = useState([])
    const [ studies, setStudies ] = useState()
    const [ html, setHtml ] = useState()
    const PatientID = row.PatientMainDicomTags?.PatientID

    useEffect(() => {

        const org = row.associateOrg?.id || row.sentBy?.org?.id || orgId

        if (PatientID && org)
          GetStudy(null, {view: 'patient', org, PatientID}).then(setStudies).catch(e=>e)

    }, [row, PatientID, orgId])

    //const reportStudies = useMemo(() => studies && studies.filter(e => e.completeAt), [studies])

    useEffect(() => {
          const thumbArray = []
          if ("Series" in row)
              for (const series of row.Series)
                  if (series.Instances && series.Instances.length && !thumbArray.some(obj => obj.id === series.id)) {
                    const i = thumbArray.push({
                      id: series.id,
                      name: series.MainDicomTags?.SeriesDescription,
                      modality: series.MainDicomTags?.Modality,
                      count: series.Instances.length,
                    })-1
                    limit(async () => await GetInstanceThumb(series.Instances[0].id).then(thumb => {
                        if (thumb)
                          setThumbs(thumbs => {
                            const copyThumbs = [...thumbs]
                            copyThumbs[i].thumb = thumb
                            return copyThumbs
                          })
                    }).catch(e => e))
                  }
        setThumbs(thumbArray)
    }, [row])

    useEffect(() => {
      GetReportHtml(row.id).then(obj => setHtml(obj.consult)).catch(e=>e)
    }, [row.id])

    return (
      <Stack
        sx={{ py: 2, height: '100%', boxSizing: 'border-box' }}
        direction="column"
      >
        <Paper sx={{ flex: 1, mx: 'auto', width: '98%', p: 1 }}>
          <Stack direction="column" spacing={1} sx={{ height: 1 }}>
            <Typography variant="h6">{`Patient ID: ${PatientID}`}</Typography>
            <ImageList sx={{ width: '74vw' }} cols={8}>
                {thumbs.map(thumb => (
                    <ImageListItem key={thumb.id}>
                        {(thumb.thumb && <img
                            srcSet={thumb.thumb}
                            src={thumb.thumb}
                            alt={thumb.name}
                          />) ||
                          <Skeleton variant="rectangular" width={146} height={133} />
                        }
                        <ImageListItemBar
                            title={thumb.name}
                            subtitle={`${thumb.modality} ${thumb.count > 1 ? `(${thumb.count})` : ''}`}
                        />
                    </ImageListItem>
                ))}
            </ImageList>
            <Grid item xs={12} sx={{"& img": {maxHeight: '17em', maxWidth: '100%'}}} dangerouslySetInnerHTML={{__html: html}}></Grid>
            {(studies && 
              <List
                sx={{ bgcolor: 'transparent' }}
                aria-labelledby="nested-list-subheader"
                subheader={
                  <ListItemButton color="secondary" dense={false} component="a" sx={{px:0}} onClick={() => studies && medDream(studies.filter(e=>e.Series?.length).map(e=>e.MainDicomTags.StudyInstanceUID).join(','))}>
                    <ListSubheader disableSticky={true} sx={{bgcolor: 'transparent', fontSize: 16}} component="div" id="nested-list-subheader" color="primary">
                      Studies (view all)
                    </ListSubheader>
                  </ListItemButton>
                }
              >
                {studies.map(study => {
                  const date = new Date(study.MainDicomTags.StudyTimestamp)
                  const outputOptions = {
                    year: "2-digit",
                    month: "numeric",
                    day: "numeric",
                    hour: study.MainDicomTags.StudyTime ? "numeric" : undefined,
                    minute: study.MainDicomTags.StudyTime ? "numeric" : undefined,
                  }
                  const reportOutputOptions = {
                    year: "2-digit",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    timeZone: study.org?.timezone || 'America/Los_Angeles'
                  }
                  const reportDate = study.completeAt && dayjs(study.completeAt.last || study.completeAt.first).toDate()
                  return (
                    <>
                      <ListItem disablePadding key={study.id}>
                        <ListItemButton dense={false} disabled={!study.Series?.length} component="a" onClick={() => medDream(study.MainDicomTags.StudyInstanceUID)}>
                          <ListItemIcon><BrokenImageIcon /></ListItemIcon>
                          <ListItemText primary={date.toLocaleString("en-US", outputOptions)} />
                        </ListItemButton>
                      </ListItem>
                      {reportDate && (
                        <ListItem sx={{ml:2}} disablePadding>
                          <ListItemButton dense={false} component="a" onClick={() => setPdfDialogState({ open: true, studyId: `${study.id}` })}>
                            <ListItemIcon><PictureAsPdfIcon /></ListItemIcon>
                            <ListItemText primary={reportDate.toLocaleString("en-US", reportOutputOptions)} />
                          </ListItemButton>
                        </ListItem>
                      )}
                    </>
                  )
                })}
              </List>
            ) ||
              <SkeletonList />
            }
            {/*(reportStudies && !!reportStudies.length &&
              <List
                sx={{ bgcolor: 'transparent' }}
                aria-labelledby="nested-list-subheader"
                subheader={
                  <ListSubheader disableSticky={true} sx={{bgcolor: 'transparent', fontSize: 16}} component="div" id="nested-list-subheader">
                    Reports
                  </ListSubheader>
                }
              >
                {reportStudies.map(study => {
                  const date = dayjs(study.completeAt.last || study.completeAt.first).toDate()
                  const outputOptions = {
                    year: "2-digit",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    timeZone: study.org?.timezone || 'America/Los_Angeles'
                  }
                  return (
                    <ListItem disablePadding key={study.id}>
                      <ListItemButton dense={false} component="a" onClick={() => setPdfDialogState({ open: true, studyId: `${study.id}` })}>
                        <ListItemIcon><PictureAsPdfIcon /></ListItemIcon>
                        <ListItemText primary={date.toLocaleString("en-US", outputOptions)} />
                      </ListItemButton>
                    </ListItem>
                  )
                })}
              </List>
              )*/}
            {!!row.media?.length && (
              <List>
                <ListSubheader disableSticky={true} sx={{bgcolor: 'transparent', fontSize: 16}} component="div" id="nested-list-subheader">
                  Multimedia
                </ListSubheader>
                {row.media.map((file, index) =>
                    <ListItem disablePadding key={file}>
                      <ListItemButton component="a" href={file}>
                        <ListItemIcon>
                            <FilePresentIcon />
                        </ListItemIcon>
                        <ListItemText sx={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}} primary={getFilename(file)} />
                      </ListItemButton>
                    </ListItem>
                )}
              </List>
            )}
          </Stack>
        </Paper>
      </Stack>
    )
  }
import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import MergeIcon from '@mui/icons-material/Merge';
import Divider from '@mui/material/Divider';
import { MergeStudies } from '../../../../api'

const displayTimestamp = tags => {
    const d = new Date(tags.StudyTimestamp)
    return d.toLocaleString("en-US", {
        year: "2-digit",
        month: "numeric",
        day: "numeric",
        hour: tags.StudyTime ? "numeric" : undefined,
        minute: tags.StudyTime ? "numeric" : undefined,
    })
}

export default function MergeDialog({dialogState}) {

    const [merge, setMerge] = useState()

    useEffect(() => {
        dialogState.merge && setMerge(dialogState.merge)
    }, [dialogState])

    const onSend = () => {
        MergeStudies(merge[0].id, {studies: merge.slice(1).map(e=>e.id)}).then(() => dialogState.onSendComplete && dialogState.onSendComplete(dialogState)).catch(e=>e)
        dialogState.onSend(dialogState)
    }

    if (!merge?.length) return <></>

    return (
        <Dialog open={dialogState.open || false} onClose={dialogState.onClose} fullWidth maxWidth="xs">
            <DialogTitle>Merge Studies</DialogTitle>
            <DialogContent>
                {merge.length && merge[0]?.consult && merge[1]?.consult && (<DialogContentText sx={{mb:2}} color="error">Both studies contain consult data, the second study's consult data may be overwritten in the merge</DialogContentText>)}
                <DialogContentText>Patient ID: {merge[0].PatientMainDicomTags?.PatientID}</DialogContentText>
                <Divider sx={{m:2}} />
                <DialogContentText>To:</DialogContentText>
                <List>
                    <ListItem>
                        <ListItemAvatar>
                        <Avatar>
                            <MergeIcon color="warning" />
                        </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={`${merge[0].PatientMainDicomTags?.PatientName} - ${merge[0].imageCount+(merge[0].media?.length||0)} images`} secondary={displayTimestamp(merge[0].MainDicomTags)} />
                    </ListItem>
                </List>
                <DialogContentText>From:</DialogContentText>
                <List>
                    {merge.slice(1).map(e =>
                        <ListItem key={e.id}>
                            <ListItemAvatar>
                            <Avatar>
                                <MergeIcon color="primary" />
                            </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={`${e.PatientMainDicomTags?.PatientName} - ${e.imageCount+(e.media?.length||0)} images`} secondary={displayTimestamp(e.MainDicomTags)} />
                        </ListItem>
                    )}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={dialogState.onClose}>Cancel</Button>
                <Button onClick={onSend}>Merge</Button>
            </DialogActions>
        </Dialog>
    );
}
import React from "react";
import { Box } from "@mui/material";
import Settings from "./Settings";
import TopNavBar from "./TopNavBar";
import SideNavBar from "./SideNavBar";

const drawerWidth = 240;

const SettingsWrapper = ({ user, access, setUser, setAccess, mobileOpen, handleDrawerToggle }) => {
  return (
    <>
      <TopNavBar handleDrawerToggle={handleDrawerToggle} user={user} access={access} setAccess={setAccess} />
      <Box sx={{ display: "flex" }}>
        <SideNavBar
          handleDrawerToggle={handleDrawerToggle}
          mobileOpen={mobileOpen}
          drawerWidth={drawerWidth}
          user={user}
          access={access}
        />
        <Box component="main" sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
          <Settings user={user} setUser={setUser} access={access} />
        </Box>
      </Box>
    </>
  )
};

export default SettingsWrapper;

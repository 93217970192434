import React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import NeedsAttentionIcon from "@mui/icons-material/NotificationImportant";
import PendingIcon from "@mui/icons-material/Pending";
import CompleteIcon from "@mui/icons-material/CheckCircle";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import TodayIcon from '@mui/icons-material/Today';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import DateRangeIcon from '@mui/icons-material/DateRange';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Link } from "react-router-dom";

const StudiesSubNavigation = ({ open, org, active }) => (
  <Collapse in={open} timeout="auto" unmountOnExit>
    <Box ml={2}>
      <List component="div" disablePadding>
        <ListItem disablePadding>
          <ListItemButton component={Link} to={`/${org.id}/clinic/today`} selected={active === 'today'}>
            <ListItemIcon>
              <TodayIcon />
            </ListItemIcon>
            <ListItemText primary="Today" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to={`/${org.id}/clinic/yesterday`} selected={active === 'yesterday'}>
            <ListItemIcon>
              <EventRepeatIcon />
            </ListItemIcon>
            <ListItemText primary="Yesterday" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to={`/${org.id}/clinic/week`} selected={active === 'week'}>
            <ListItemIcon>
              <DateRangeIcon />
            </ListItemIcon>
            <ListItemText primary="Past Week" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to={`/${org.id}/clinic/all`} selected={active === 'all'}>
            <ListItemIcon>
              <CalendarMonthIcon />
            </ListItemIcon>
            <ListItemText primary="All" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to={`/${org.id}/clinic/attention`} selected={active === 'attention'}>
            <ListItemIcon>
              <NeedsAttentionIcon />
            </ListItemIcon>
            <ListItemText primary="Needs Attention" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to={`/${org.id}/clinic/pending`} selected={active === 'pending'}>
            <ListItemIcon>
              <PendingIcon />
            </ListItemIcon>
            <ListItemText primary="Pending" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to={`/${org.id}/clinic/complete`} selected={active === 'complete'}>
            <ListItemIcon>
              <CompleteIcon />
            </ListItemIcon>
            <ListItemText primary="Complete" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  </Collapse>
);

export default StudiesSubNavigation;

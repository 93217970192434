import React, { useState } from 'react';
import { Container, TextField, Button, Grid, Box, Typography } from '@mui/material';
import { CreateAccount as ApiCreateAccount } from '../../../api';
import VerifyCode from './VerifyCode';
import { validate } from 'validate.js';
import constraints from '../validate-email-constraints';

const CreateAccount = ({ setAuthenticated }) => {

  const [name, setName] = useState('');
  const [suffix, setSuffix] = useState('');
  const [email, setEmail] = useState('');
  const [info, setInfo] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordInvalid, setConfirmPasswordInvalid] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [verifyStage, setVerifyStage] = useState(false);

  const handleSubmit = async e => {
    const emailAddress = email.toLowerCase().trim()
    e.preventDefault();
    setSubmitError(false);
    const validation = validate({emailAddress}, constraints);

    if (validation)
      setSubmitError(validation.emailAddress)
    else {
      ApiCreateAccount({name, email: emailAddress, password, suffix, info}).then(() => {
        setVerifyStage(true);
      }).catch(e => {
        const error = (e.response && e.response.data && e.response.data.errors) || e.message
        setSubmitError(error);
      });
    }
  }

  const validateConfirmPassword = e => {
    setConfirmPassword(e.target.value);
    setConfirmPasswordInvalid(password !== e.target.value);
  }

  if (verifyStage)
    return (
      <VerifyCode setAuthenticated={setAuthenticated} email={email} password={password} />
    );

  return (
    <Container>
      <Typography component="h1" variant="h4">Create Account</Typography>
      {submitError ? <Typography component="p" color="error">{submitError}</Typography> : null}
      <form onSubmit={handleSubmit}>
        <TextField sx={{marginTop: 1}} value={name} name="name" fullWidth={true} required={true} label="Full name" id="name" onChange={e => setName(e.target.value)} />
        <TextField sx={{marginTop: 1}} value={suffix} name="suffix" fullWidth={true} required={false} label="Professional suffix" id="suffix" onChange={e => setSuffix(e.target.value)} /> 
        <TextField sx={{marginTop: 1}} value={email} name="email" fullWidth={true} required={true} label="Email address" id="email" onChange={e => setEmail(e.target.value)} />
        <TextField sx={{marginTop: 1}} value={info} name="info" fullWidth={true} required={false} label="Organization" id="info" onChange={e => setInfo(e.target.value)} />
        <TextField sx={{marginTop: 1}} value={password} name="password" type="password" fullWidth={true} required={true} label="Password" id="password" onChange={e => setPassword(e.target.value)} />
        <TextField sx={{marginTop: 1}} value={confirmPassword} name="confirmPassword" type="password" fullWidth={true} error={confirmPasswordInvalid} required={true} label="Confirm Password" id="confirmPassword" onChange={validateConfirmPassword} />
        <Grid container justifyContent="center">
          <Box mt={3}>
            <Button variant="contained" color="primary" type="submit">Create</Button>
          </Box>
        </Grid>
      </form>
    </Container>
  );
}

export default CreateAccount;

import React from "react";
import { Box } from "@mui/material";
import Dashboard from "./Dashboard";
import TopNavBar from "./TopNavBar";
import SideNavBar from "./SideNavBar";
import Alerts from "./Alerts";

const drawerWidth = 240;

const DashWrapper = ({ user, access, setAccess, mobileOpen, handleDrawerToggle, alerts, setAlerts, socketStatus }) => {
  return (
    <>
      <TopNavBar handleDrawerToggle={handleDrawerToggle} user={user} access={access} setAccess={setAccess} />
      <Alerts alerts={alerts} setAlerts={setAlerts} />
      <Box sx={{ display: "flex" }}>
        <SideNavBar
          handleDrawerToggle={handleDrawerToggle}
          mobileOpen={mobileOpen}
          drawerWidth={drawerWidth}
          user={user}
          access={access}
        />
        <Box component="main" sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
          <Dashboard user={user} access={access} socketStatus={socketStatus} />
        </Box>
      </Box>
    </>
  )
};

export default DashWrapper;

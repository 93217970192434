import React, { useState, useEffect } from "react";
import { Grid, Typography, Paper } from "@mui/material";
import styled from "styled-components";
import moment from "moment";
import { GetReportHtml } from "../../../../../../api";

export const Wrapper = styled(Paper)`
  width: 800px;
  margin-bottom: 2em;
`;

const ReportingData = (props) => {
  const { study, report } = props;
  const [ html, setHtml ] = useState()

  let numImages = 0
  study.Series && study.Series.map(e =>
    e.Instances && e.Instances.map(f => ++numImages)
  )  

  useEffect(() => {
    GetReportHtml(study.id).then(obj => setHtml(obj.consult + (report ? obj.report : ''))).catch(e=>e)
  }, [study, report])

  if (!study) return <React.Fragment />;

  return (
    <Wrapper elevation={0}>
      <Grid container spacing={3} justifyContent="left" maxWidth="md">
        <Grid item xs={5}>
          <Typography variant="h6">Patient:</Typography>
          <Typography variant="body1">
            ID: {study.PatientMainDicomTags.PatientID}
          </Typography>
          <Typography variant="body1">
            Name: {study.PatientMainDicomTags.PatientName}
          </Typography>
          <Typography variant="body1">
            Owner: {study.PatientMainDicomTags.ResponsiblePerson}
          </Typography>
          <Typography variant="body1">
            Species: {study.PatientMainDicomTags.PatientSpeciesDescription}
          </Typography>
          <Typography variant="body1">
            Breed: {study.PatientMainDicomTags.PatientBreedDescription}
          </Typography>
          <Typography variant="body1">
            DOB:{" "}
            {moment(study.PatientMainDicomTags.PatientBirthDate).format(
              "MM-DD-YYYY"
            )}
          </Typography>
          <Typography variant="body1">
            Age:{" "}
            {moment.duration(moment().diff(study.PatientMainDicomTags.PatientBirthDate)).humanize()}
          </Typography>
          <Typography variant="body1">
            Sex: {study.PatientMainDicomTags.PatientSex}
          </Typography>
          <Typography variant="body1">
            Weight: {study.PatientMainDicomTags.PatientWeight ? study.PatientMainDicomTags.PatientWeight+"kg" : ""}
          </Typography>
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={5}>
          {study.MainDicomTags.ReferringPhysicianName && (
            <>
              <Typography variant="h6">Referring Physician:</Typography>
              <Typography sx={{mb:2}} variant="body1">{study.MainDicomTags.ReferringPhysicianName}</Typography>
            </>
          )}
          <Typography variant="h6">Requested by:</Typography>
          {(study.associateOrg && (
            <>
              <Typography variant="body1">{study.associateOrg.name}</Typography>
              <Typography variant="body1">{study.associateOrg.address}</Typography>
              <Typography variant="body1">
                {study.associateOrg.city}, {study.associateOrg.state}&nbsp;
                {study.associateOrg.zip}
              </Typography>
              <Typography variant="body1">{study.associateOrg.phone}</Typography>
              <Typography variant="body1">{study.associateOrg.email}</Typography>
            </>
          )) || (
            <>
              <Typography variant="body1">{study.consultBy.org.name}</Typography>
              <Typography variant="body1">{study.consultBy.org.address}</Typography>
              <Typography variant="body1">
                {study.consultBy.org.city}, {study.consultBy.org.state}&nbsp;
                {study.consultBy.org.zip}
              </Typography>
              <Typography variant="body1">{study.consultBy.org.phone}</Typography>
              <Typography variant="body1">{study.consultBy.org.email}</Typography>
            </>
          )}
        </Grid>
        <Grid item xs={5} sx={{mt: 1}}>
          <Typography>Number of images: {numImages + (study.numImages || study.numImages === 0 ? ` of ${study.numImages}` : '')}</Typography>
        </Grid>
        <Grid item xs={12} sx={{"& img": {maxHeight: '17em', maxWidth: '100%'}}} dangerouslySetInnerHTML={{__html: html}}></Grid>
      </Grid>
    </Wrapper>
  );
};
export default ReportingData;

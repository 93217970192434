import React, { useState, useEffect, useRef, useCallback } from "react";
import { Button, Dialog, DialogContent, DialogActions } from "@mui/material";
import * as pdfjs from "pdfjs-dist/build/pdf";
//import * as workerSrc from 'pdfjs-dist/build/pdf.worker';
import { api } from "../../../../api"

//pdfjs.GlobalWorkerOptions.workerSrc = workerSrc
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.mjs`

const PdfPreview = ({ open, studyId, setDialogState }) => {

    const canvasRef = useRef()
    const [pdfRef, setPdfRef] = useState()
    const [currentPage, setCurrentPage] = useState(1)
    const [canvasState, setCanvasState] = useState({})

    const handleClose = useCallback(() =>
        setDialogState({ open: false })
    , [setDialogState])

    const renderPage = useCallback((pageNum, pdfRef) => {
        pdfRef && pdfRef.getPage(pageNum).then(page => {
            const viewport = page.getViewport({ scale: 1.4 })
            const { width, height } = viewport
            setCanvasState({width, height, page, viewport})
        });
    }, [])

    useEffect(() => {
        const { page, viewport } = canvasState
        const canvas = canvasRef.current
        if (page && canvas) {
            const renderContext = {
                canvasContext: canvas.getContext("2d"),
                viewport: viewport,
            }

            page.render(renderContext)
        }
    }, [canvasState, canvasRef])

    useEffect(() => {
        renderPage(currentPage, pdfRef);
    }, [pdfRef, currentPage, renderPage]);

    useEffect(() => {
        return () => setCurrentPage(1)
    }, [open])

    useEffect(() => {
        if (studyId) {
            const loadingTask = pdfjs.getDocument(`${api}studies/${studyId}/report/embed`)
            loadingTask.promise.then(setPdfRef).catch(e=>e)
        }
    }, [studyId]);
    const nextPage = () =>
        pdfRef && currentPage < pdfRef.numPages && setCurrentPage(currentPage + 1);

    const prevPage = () => currentPage > 1 && setCurrentPage(currentPage - 1);

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogContent>
        <canvas ref={canvasRef} width={canvasState.width || 0} height={canvasState.height || 0}></canvas>
        <DialogActions>
          {currentPage > 1 && (
            <Button onClick={prevPage} size="small">
              Previous
            </Button>
          )}
          {pdfRef && currentPage < pdfRef.numPages && (
            <Button onClick={nextPage} size="small">
              Next
            </Button>
          )}
        </DialogActions>
      </DialogContent>
      <DialogActions>
        <Button
          component="a"
          href={`${api}studies/${studyId}/report/report`}
          color="primary"
        >
          Download
        </Button>
        <Button color="secondary" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PdfPreview;

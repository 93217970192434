import { Typography, Link } from '@mui/material';

const Home = ({ user }) => {
    console.log('meow', user.org)
    return (
        <>
            <Typography variant='h3'>Hello {user.name}!</Typography>
            {!user.org?.id && (<Typography sx={{mt:3}} color="secondary">This account hasn't yet been assigned permissions. Please contact <Link href="mailto:support@corridor.vet">support@corridor.vet</Link> to get started.</Typography>)}
        </>

    )


}

export default Home;
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Grid } from '@mui/material';
import CreateAccount from './CreateAccount';
import Login from './Login';
import loginbg from './loginbg.jpg';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    primary: {
      main: grey[800]
    },
    secondary: {
      main: grey[300]
    },
    action: {
       active: grey[800],
       activeOpacity: 1,
       hover: grey[500],
       hoverOpacity: 0.7,
       focus: grey[500],
       focusOpacity: 1,
       selected: grey[300],
       selectedOpacity: 1
     },
  },
  typography: {
    button: {
      fontWeight: 400
    },
    h1: {
      fontWeight: 100
    },
  },
});

const AccountAccess = ({ setAuthenticated }) => {

  return (
    <ThemeProvider theme={theme}>
      <Grid container direction="column" alignItems="center" justifyContent="center" sx={{backgroundImage: `url(${loginbg})`,backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat',backgroundColor: '#000',width: '100vw',height: '100vh',paddingBottom: '15vh'}}>
        <Grid item sx={{backgroundColor: 'rgba(255,255,255,0.75)',padding: 3,borderRadius: 3,border: '1px solid #aaa'}}>
          <Routes>
            <Route path="/create-account" element={<CreateAccount setAuthenticated={setAuthenticated} />} />
            <Route path="*" element={<Login setAuthenticated={setAuthenticated} />} />
          </Routes>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default AccountAccess;

import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { SendPackage } from '../../../../api'

export default function SendDialog({dialogState}) {

    const [reportData, setReportData] = useState({email: ''})
    const [error, setError] = useState(false)

    useEffect(() => {
        setReportData(x => ({
            email: x.email,
            report: !dialogState.disableReport,
            zip: !dialogState.disableZip,
            view: !dialogState.disableView
        }))
    }, [dialogState])

    const reportChange = e => {
        setReportData(x => ({...x, [e.target.name]: e.target.value}))
        setError(false)
    }
    const reportCheckChange = e => setReportData(x => ({...x, [e.target.name]: e.target.checked}))

    const onSend = () => {
        if (reportData.email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
            SendPackage(dialogState.studyId, reportData).then(() => dialogState.onSendComplete && dialogState.onSendComplete(dialogState)).catch(e=>e)
            dialogState.onSend(dialogState)
        }
        else
            setError('Invalid email address')
    }

  return (
    <Dialog open={dialogState.open || false} onClose={dialogState.onClose} fullWidth maxWidth="xs">
        <DialogTitle>Send Report Package</DialogTitle>
        <DialogContent>
            {error && (<DialogContentText color="error">{error}</DialogContentText>)}
            <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Email Address"
                type="email"
                name="email"
                fullWidth
                variant="standard"
                error={!!error}
                value={reportData.email}
                onChange={reportChange}
                required
            />
            <FormGroup>
                <FormControlLabel control={<Switch disabled={!!dialogState.disableReport} checked={reportData.report} name="report" onChange={reportCheckChange} />} label="Teleconsultation report pdf" />
                <FormControlLabel control={<Switch disabled={!!dialogState.disableZip} checked={reportData.zip} name="zip" onChange={reportCheckChange} />} label="Study download zip" />
                <FormControlLabel control={<Switch  disabled={!!dialogState.disableView} checked={reportData.view} name="view" onChange={reportCheckChange} />} label="Study viewer link" />
            </FormGroup>
        </DialogContent>
        <DialogActions>
            <Button onClick={dialogState.onClose}>Cancel</Button>
            <Button onClick={onSend}>Send</Button>
        </DialogActions>
    </Dialog>
  );
}
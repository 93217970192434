import { Routes, Route } from "react-router-dom";
import UserSettings from "./User"

const Settings = ({ user, access, setUser }) => {

    return (
        <Routes>
            <Route path="/user" element={<UserSettings user={user} setUser={setUser} />} />
        </Routes>
    )

}

export default Settings;
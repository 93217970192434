import React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import PendingIcon from "@mui/icons-material/Pending";
import CompleteIcon from "@mui/icons-material/CheckCircle";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import { Link } from "react-router-dom";

const TeleconsultationSubNavigation = ({ open, org, active }) => (
  <Collapse in={open} timeout="auto" unmountOnExit>
    <Box ml={2}>
      <List component="div" disablePadding>
        <ListItem disablePadding>
          <ListItemButton component={Link} to={`/${org.id}/specialist/pending`} selected={active === 'pending'}>
            <ListItemIcon>
              <PendingIcon />
            </ListItemIcon>
            <ListItemText primary="Pending" />
          </ListItemButton>
        </ListItem>
        <Box ml={2}>
          <ListItem disablePadding>
            <ListItemButton component={Link} to={`/${org.id}/specialist/pending-unclaimed`} selected={active === 'pending-unclaimed'}>
              <ListItemIcon>
                <AssignmentLateIcon />
              </ListItemIcon>
              <ListItemText primary="Unclaimed" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component={Link} to={`/${org.id}/specialist/pending-claimed`} selected={active === 'pending-claimed'}>
              <ListItemIcon>
                <AssignmentTurnedInIcon />
              </ListItemIcon>
              <ListItemText primary="My Claims" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component={Link} to={`/${org.id}/specialist/pending-available`} selected={active === 'pending-available'}>
              <ListItemIcon>
                <IntegrationInstructionsIcon />
              </ListItemIcon>
              <ListItemText primary="My Available" />
            </ListItemButton>
          </ListItem>
          {org.settings?.prelim && (
            <ListItem disablePadding>
              <ListItemButton component={Link} to={`/${org.id}/specialist/prelim`} selected={active === 'prelim'}>
                <ListItemIcon>
                  <NoteAltIcon />
                </ListItemIcon>
                <ListItemText primary="Preliminary" />
              </ListItemButton>
            </ListItem>
          )}
        </Box>
        <ListItem disablePadding>
          <ListItemButton component={Link} to={`/${org.id}/specialist/complete`} selected={active === 'complete'}>
            <ListItemIcon>
              <CompleteIcon />
            </ListItemIcon>
            <ListItemText primary="Complete" />
          </ListItemButton>
        </ListItem>
        <Box ml={2}>
          <ListItem disablePadding>
              <ListItemButton component={Link} to={`/${org.id}/specialist/complete-claimed`} selected={active === 'complete-claimed'}>
                <ListItemIcon>
                  <LibraryAddCheckIcon />
                </ListItemIcon>
              <ListItemText primary="My Complete" />
            </ListItemButton>
          </ListItem>
        </Box>
      </List>
    </Box>
  </Collapse>
);

export default TeleconsultationSubNavigation;

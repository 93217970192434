import { DataGridPremium, gridClasses } from "@mui/x-data-grid-premium";
import { styled } from '@mui/material/styles';
import React from "react";

const DataGridColorWrapper = styled(DataGridPremium)(({ theme }) => ({
  [`& .${gridClasses.row}.prio--10`]: {
    backgroundColor: '#533',
    '&:hover, &.Mui-hovered': {
      backgroundColor: '#644',
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: '#544',
      '&:hover, &.Mui-hovered': {
        backgroundColor: '#655',
      },
    },
  },
  [`& .${gridClasses.row}.prio--5`]: {
    backgroundColor: '#334',
    '&:hover, &.Mui-hovered': {
      backgroundColor: '#445',
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: '#556',
      '&:hover, &.Mui-hovered': {
        backgroundColor: '#667',
      },
    },
  },
}));


const DataGrid = (params) => {

  return (
    <DataGridColorWrapper
      getRowClassName={params => `prio--${params.row.priority || 0}`}
      {...params}
    />
  )
}

export default DataGrid